import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
    * {
        margin: 0 auto;
        padding: 0;
        background-color: #880B0B;
        font-family: 'Afacad', sans-serif;
    }

    body {
        width: 100%;
    }
`

export default GlobalStyle;