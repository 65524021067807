import { Navbar } from "./styles"


const LayoutNav = () => {
    return(
        <Navbar>
            <img src="../../../assets/logo-header.png" alt="" />
        </Navbar>
    )
}

export default LayoutNav;