import LayoutNav from "../../Components/Navbar";
import SupEmail from "../../Components/Support";
import { ScreenWrapper } from "../../Components/Wrappers/styles";
import GlobalStyle from "../../GlobalLayout/globalStyles";

const SupportPage = () => {
    return(
        <>
            <GlobalStyle/>
            <LayoutNav/>

            <ScreenWrapper>
                <SupEmail/>    
            </ScreenWrapper>
        </>
    )
}

export default SupportPage;