import styled from "styled-components";

export const Head1 = styled.h1`
    font-size: 22px;
    color: white;
    text-align: center;
    margin: 1rem 0;
    text-shadow: black 1px 0 10px;
    max-width: 100%;

    @media screen and (min-width: 768px){
        font-size: 44px;
    }
`

export const Head2 = styled.h2`
    font-size: 22px;
    color: white;
    text-align: center;
    margin: 1rem 0;
    text-shadow: black 1px 0 10px;
    max-width: 100%;
`
