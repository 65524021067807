import styled from "styled-components";

export const FooterN = styled.footer`
    position: fixed;
    display: flex;
    background-color: #FFFFFF;
    width: 100vw;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    left: 0;
    bottom: 0;

    @media screen and (min-width: 768px){
        font-size: 2.5vh;
    }
`