import styled from "styled-components";

export const WrapperPDF = styled.div`
    margin: 0 auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    img {
        max-width: 100%;
        margin: 0 auto;
    }
`

export const ViewerAlert = styled.div`
    width: 100%;
    height: 3rem;
    background-color: white;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding: 0.9rem 0;

    div {
        width: 80%;
    }

    h2, h3 {
        background-color: white;
    }

    h3 {
        font-size: 16px;
    }

    @media screen and (max-width: 768px){
        h2, h3 {
            font-size: 10px;
        }
    }
`