import { openProd } from "../../Funcs/mainFuncs";
import { LargeProdBtn, SupBtn } from "../Buttons/styles";
import { Head2 } from "../Headlines/styles";
import { ScreenWrapper } from "../Wrappers/styles";
import { ContainProd, LProd, PBox, Products } from "./styles";

const ProductBox = () => {

    const linkProd0 = 'https://leanbliss24.com/text.php';
    const linkProd1 = 'https://theflatbellyshake.com/discovery/?aff=MPDigitalMarketing';
    const linkProd2 = 'https://digi.hormonalbalancenow.com/#aff=MPDigitalMarketing';
    const linkSup = 'https://nighttime-site.online/support';

    return(
        <Products>

            <ContainProd>
                <PBox>
                    <a href="/flabiness-material" target="_blank" rel="noreferrer">
                        <img src="../../assets/anti-flab.png" alt=""/>
                    </a>

                    <a href="/modelbody-material" target="_blank" rel="noreferrer">
                        <img src="../../assets/model-body.png" alt=""/>
                    </a>     
                </PBox>
            </ContainProd>

            <ContainProd>
                <PBox>
                    <a href="/fountain-material" target="_blank" rel="noreferrer">
                        <img src="../../assets/fountain-youth.png" alt="" />
                    </a>

                    <a href="/colagen-material" target="_blank" rel="noreferrer">
                        <img src="../../assets/collagen-bio.png" alt=""/>
                    </a>   
                </PBox>
            </ContainProd>

            <ContainProd>
                <PBox>
                    <a href="/diabetes-material" target="_blank" rel="noreferrer">
                        <img src="../../assets/diabetes-prod.webp" alt="" />
                    </a>

                    <a href="/nighttime-material" target="_blank" rel="noreferrer">
                        <img src="../../assets/nighttime-prod.jpeg" alt=""/>
                    </a>   
                </PBox>
            </ContainProd>

            <ScreenWrapper>
        
                <SupBtn onClick={() => {openProd(linkSup)}}>➡️ Quick Support ⬅️</SupBtn>
        
            </ScreenWrapper>

            <hr/>
            <Head2>We have selected some <br/> Exclusive Offers for you</Head2>
            <hr />
            <Head2>#TOP 1</Head2>
            <LProd>
                <img src="../../assets/product-0.webp" alt="" onClick={() => openProd(linkProd0)}/>
            </LProd>
            <a href={linkProd0} target="_blank" rel="noreferrer">
                <LargeProdBtn>See more details...</LargeProdBtn>
            </a>
            
            <hr/>

            <Head2>#TOP 2</Head2>   
            <LProd>
                <img src="../../assets/product-1.webp" alt="" onClick={() => openProd(linkProd1)} />
            </LProd>
            <a href={linkProd1} target="_blank" rel="noreferrer">
                <LargeProdBtn>See more details...</LargeProdBtn>
            </a>
            <hr/>
            
            <Head2>#TOP 3</Head2>
            <LProd>
                <img src="../../assets/product-2.webp" alt="" onClick={() => openProd(linkProd2)}/>
            </LProd>
            <a href={linkProd2} target="_blank" rel="noreferrer">
                <LargeProdBtn>See more details...</LargeProdBtn>
            </a>
            <hr/>
            
        </Products>
    )
}

export default ProductBox;