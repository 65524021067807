import GlobalStyle from "../../GlobalLayout/globalStyles";
import { ViewerAlert, WrapperPDF } from "./styles";

const ProdView = () => {
    return(    
        <>  
            <GlobalStyle/>
            <WrapperPDF>
                <ViewerAlert>
                    <div>
                        <h2>It's time to take care of yourself!</h2>
                        <h3>Attention: If you are viewing the content on a mobile phone, it may be better to use your cell phone horizontally. This will make your reading considerably easier!</h3>
                    </div>
                    
                </ViewerAlert>
                <img src="../../assets/nighttime-merge.webp" alt="nighttime-material" />
            </WrapperPDF>
        </>
    )
}

export default ProdView;