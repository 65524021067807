import styled from "styled-components";

export const Products = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin-top: 3vh;
    margin-bottom: 6vh;
    width: 100%;

    hr {
        width: 90%;
    }
`

export const ContainProd = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;    
`

export const PBox = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 2rem;
    cursor: pointer;

    a img {
        border-radius: 6px;
        box-shadow: 1px 1px 10px;
        border: 1px solid black;
        border-radius: 6px;
        height: 7rem;
        width: 7rem;

        @media screen and (min-width: 768px){
            width: 10rem;
            height: 10rem;
        }
    }
`

export const PBox2 = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    overflow: hidden;

    img {
        border-radius: 6px;
        box-shadow: 1px 1px 10px;
        border: 1px solid black;
        border-radius: 6px;
        width: 16rem;

        @media screen and (min-width: 768px){
            width: 10rem;
            height: 10rem;
        }
    }
`

export const LProd = styled.div`
    display: flex;
    width: 100%;

    img {
        overflow: hidden;
        width: 300px;
        cursor: pointer;
        border: 2px solid #F9A909;
        border-radius: 30px;
        box-shadow: 1px 1px 10px;
    }
`