import { useState } from "react";
import { FooterN } from "../Footer/styles";
import { SupLay, SupSend } from "./styles";
import emailjs from '@emailjs/browser';

const SupEmail = () => {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const sendEmail = (e: any) => {
        e.preventDefault();

        if(name === '' || email === '' || message === '') {
            alert("Please, fill in all fields!");
            return;
        }

        const templateParams = {
            from_name: name,
            from_email: email,
            message: message
        }
    
        emailjs.send('service_y18azjl', 'template_sy9tlnc', templateParams, '3R7V_FZkr0KByFW_M')
          .then((result: any) => {
              console.log(result)
              alert('Message Sucess!');
              setName('');
              setEmail('');
              setMessage('');
          }, (error: any) => {
              console.log(error.text);
          });
      };

    return(
        <SupLay>
            <h1>Tell us, how can<br/> we help you?</h1>
            <hr />
            <p>
                Having difficulties with the material? <br/>
                We will help you in the best way possible. <br/>
                Our response time is 2 to 6 hours.
            </p>

            <hr/>
            <br/>

            <input 
                type="text" 
                name="from_name" 
                id="from_name" 
                placeholder="Name:" 
                value={name}
                onChange={(e) => setName(e.target.value)}
            />

            <input 
                type="email" 
                name="from_email" 
                id="from_email" 
                placeholder="Email:" 
                value={email}
                onChange={(e) => setEmail(e.target.value)}
            />

            <textarea 
                name="message" 
                id="message" 
                placeholder="Message:" 
                value={message}
                onChange={(e) => setMessage(e.target.value)}
            />

            <SupSend onClick={sendEmail}>Send Message!</SupSend>

            <FooterN>
                Secure Website - Copywriting 2023 ©️
             </FooterN> 
        </SupLay>
    )
};

export default SupEmail;