import { AcessProd } from "./Components/Buttons/styles";
import { FooterN } from "./Components/Footer/styles";
import { Head1 } from "./Components/Headlines/styles";
import ImageWatch from "./Components/ImageWatch";
import LayoutNav from "./Components/Navbar";
import ProductBox from "./Components/ProductBox";
import { ScreenWrapper } from "./Components/Wrappers/styles";
import GlobalStyle from "./GlobalLayout/globalStyles";

const App = () => {

  return (
    <>
      <GlobalStyle/>

      <LayoutNav/>

      <ScreenWrapper>
        <ImageWatch/>
      </ScreenWrapper>

      <ScreenWrapper>
        <a href="/nighttime-material" target="_blank" rel='noreferrer'>
          <AcessProd>Click Here to Access</AcessProd>
        </a>  
      </ScreenWrapper>

      <hr style={{width: '90%'}}/>

      <Head1>Here you will find your complete <br/> Weight Loss Material!</Head1>

      <hr style={{width: '90%'}}/>

      <ProductBox/>
      
      <hr/>

      <FooterN>
        Secure Website - Copywriting 2023 ©️
      </FooterN> 
    </>
  );
}

export default App;
