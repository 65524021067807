import styled from "styled-components";

export const Navbar = styled.header`
    max-width: 100vw;
    height: auto;
    background-color: #880B0B;
    display: flex;
    justify-content: center;
    align-items: center;
    
    img {
        max-width: 80%;
        margin: 3vh auto;
    }

    @media screen and (min-width: 768px) {
        width: 50vh;
    }
`;
