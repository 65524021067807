import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ProdView from './Components/ProdView';
import ProdView2 from './Components/ProdView2';
import ProdView3 from './Components/ProdView3';
import ProdView4 from './Components/ProdView4';
import ProdView5 from './Components/ProdView5';
import ProdView6 from './Components/ProdView6';
import SupportPage from './Pages/Support';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<App />}/>

        <Route path='/nighttime-material' element={<ProdView/>}/>
        <Route path='/flabiness-material' element={<ProdView2/>}/>
        <Route path='/modelbody-material' element={<ProdView3/>}/>
        <Route path='/fountain-material' element={<ProdView4/>}/>
        <Route path='/colagen-material' element={<ProdView5/>}/>
        <Route path='/diabetes-material' element={<ProdView6/>}/>

        <Route path='/support' element={<SupportPage/>}/>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();
