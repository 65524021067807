import { WatchImg, WrapViewFirst } from "./styles";

const ImageWatch = () => {
    return(
        <>
            <WrapViewFirst>

                <a href="/nighttime-material" target="_blank">
                    <WatchImg src="../../assets/watch-nighttime.png"></WatchImg>
                </a>
                
            </WrapViewFirst>
            
        </>
    )
};

export default ImageWatch;