import styled from "styled-components";

export const ScreenWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 0 auto;    
`

export const BonusWrapper = styled.img`
    width: 70%;

    @media screen and (min-width: 768px){
        width: 40%;
    }
`