import styled from "styled-components";

export const SupLay = styled.form`
    width: 80%;
    border: 1px solid black;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #F8F7D9;
    padding: 3rem 0;
    gap: 0.3rem;
    margin-bottom: 3rem;

    h1, p {
        background-color: #F8F7D9;
    }

    h1 {
        margin-bottom: 1rem;
    }

    hr {
        border-color: inherit;
        width: 80%;
    }

    p {
        margin: 1rem 0;
    }

    input, textarea#message{
        background-color: white;
        border-radius: 5px;
        border: 0.5px solid black;
        width: 80%;
        padding: 3px 4px;
    }

    input::placeholder, textarea#message::placeholder {
        padding: 3px;
    }

    textarea#message {
        height: 6rem;
    }

    @media screen and (min-width: 768px){
        width: 50%;
    }
`

export const SupSend = styled.button`
    background-color: green;
    border-radius: 16px;
    border: 1px solid black;
    margin-top: 1rem;
    padding: 0.5rem 1rem;
    font-size: 18px;
    color: white;
`