import styled from "styled-components";

export const AcessBtn = styled.button`
    position: absolute;
    height: 2rem;
    width: 6rem;
    right: 7%;
    top: 30%;
    border: 1px solid black;
    border-radius: 6px;
    background-color: green;
    color: white;
    box-shadow: 1px 1px 10px;
    cursor: pointer;

    @media screen and (min-width: 768px){
        right: 30vw;
        top: 30%; 
    }
`

export const LargeProdBtn = styled.button`
    background-color: green;
    color: white;
    border-radius: 16px;
    border: 1px solid black;
    box-shadow: 1px 1px 10px;
    padding: 0.5rem 1rem;
    cursor: pointer;
`

export const SupBtn = styled.button`
    background-color: #0078CF;
    color: white;
    font-size: 18px;
    padding: 1rem;
    border: 1px solid black;
    border-radius: 16px;
    cursor: pointer;
`

export const AcessProd = styled.button`
    background-color: green;
    color: white;
    padding: 1rem 2rem;
    border: 1px solid black;
    border-radius: 16px;
    margin-bottom: 1rem;
    cursor: pointer;
    font-size: 18px;
`