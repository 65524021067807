import GlobalStyle from "../../GlobalLayout/globalStyles";
import { ViewerAlert, WrapperPDF } from "../ProdView/styles";

const ProdView3 = () => {
    return(    
        <>  
            <GlobalStyle/>
            <WrapperPDF>
                <ViewerAlert>
                    <h2>It's time to take care of yourself!</h2>
                    <h3>Attention: If you are viewing the content on a mobile phone, it may be better to use your cell phone horizontally. This will make your reading considerably easier!</h3>
                </ViewerAlert>
                <img src="../../assets/bodyprog-merge.png" alt="nighttime-material" />
            </WrapperPDF>
        </>
    )
}

export default ProdView3;