import styled from "styled-components";

export const WatchImg = styled.img`
    width: 100%;

    @media screen and (min-width: 768px){
        width: 50%;
    }
`

export const WrapViewFirst = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100vw;
    margin-bottom: 3vh;


    a {
            background-color: #2B2C31;
    }
`